import PanelItem from "./PanelItem"
import PanelsList from "./PanelsList"
import Cookies from 'js-cookie';

function Panels() {
  const isDownload = Cookies.get('isDownload') === 'true'

  return (
    <>
      <PanelsList />
      { isDownload ? null : <PanelItem /> }
    </>
  )
}

export default Panels
