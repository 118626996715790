import { createClient } from "@supabase/supabase-js";
import { nanoid } from "nanoid";

const supabase = createClient("https://igalgzepbejgggxvyder.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlnYWxnemVwYmVqZ2dneHZ5ZGVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk1MjM5NzcsImV4cCI6MjAzNTA5OTk3N30.spwnsmuetBcgN4DyAyTxHpUtIVzEgNyF6KvJnZkldf4");

export const listImages = async (projectId: string) => {
    let { data, error } = await supabase
      .storage
      .from('images')
      .list(projectId, {
        limit: 100,
        offset: 0,
        sortBy: { column: 'name', order: 'asc' },
      })
  
    if (error) {
      // Handle error
      console.error("Error listing images",error)
      return
    }
  
    let imageulrs = []
    //call getPublicUrl for each image
    data?.forEach((image) => {
      let res = supabase
        .storage
        .from('images')
        .getPublicUrl(`${projectId}/${image.name}`)
      
      imageulrs.push({
        id: nanoid(),
        src: res.data.publicUrl,
        preview: res.data.publicUrl,
        type: "StaticImage",
      })
  
    })
    
    return imageulrs
}

export const getSkuData = async (sku?: string) => {
    
    let { data, error } = await supabase
      .from('product')
      .select('*')
      .eq('sku', sku || 'SCS-24680')
      .single()
  
    if (error) {
      // Handle error
      console.error("Error getting sku data",error)
      return
    }
  
    return data
}

export const getSavedProject = async (designId: string, customerId: string) => {
    let { data, error } = await supabase
      .from('projects')
      .select('*')
      .eq('design_id', designId)
      .eq('customer_id', customerId)
      .single()
  
    if (error) {
      // Handle error
      console.error("Error getting saved project",error)
      return
    }
  
    return data
}

export const getProjectForDownload = async (id: string) => {
  let { data, error } = await supabase
    .from('order_item')
    .select('design')
    .eq('order_item_id', id)
    .single()

  if (error) {
    // Handle error
    console.error("Error getting saved project",error)
    return
  }

  return data
}

export const setCartItemDesign = async (uuid: string, design: any) => {
    let { data, error } = await supabase
      .from('cart_item')
      .update({ design: design })
      .eq('uuid', uuid)
  
    if (error) {
      // Handle error
      console.error("Error setting cart item design",error)
      return
    }
  
    return data
}
  
export const uploadFile = async (projectId: string, file: File) => {
    const { data, error } = await supabase.storage.from('images').upload(projectId, file)
    if (error) {
      // Handle error
      console.error("Error uploading",error)
      return
    } else {
      // Handle success
      return data
    }
}