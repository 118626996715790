import React, { useCallback, useEffect, useState } from "react"
import { useStyletron } from "baseui"
import Add from "~/components/Icons/Add"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { nanoid } from "nanoid"
import { getDefaultTemplate } from "~/constants/design-editor"
import { useEditor, useFrame } from "@layerhub-io/react"
import { IScene } from "@layerhub-io/types"
import { DndContext, closestCenter, PointerSensor, useSensor, DragOverlay } from "@dnd-kit/core"
import { arrayMove, SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import SceneItem from "./SceneItem"
import { Block } from "baseui/block"
import useContextMenuTimelineRequest from "~/hooks/useContextMenuTimelineRequest"
import SceneContextMenu from "./SceneContextMenu"

import { createClient } from "@supabase/supabase-js";
import Cookies from 'js-cookie';
import formatSizes from "~/constants/format-sizes"
import { exampleTemplate } from "~/constants/templates/exampleTemplate"
import { loadVideoEditorAssets } from "~/utils/video"
import { loadTemplateFonts } from "~/utils/fonts"
import { IDesign } from "~/interfaces/DesignEditor"
import useAppContext from "~/hooks/useAppContext"
import { scaleTemplate } from "~/utils/layouts"

export default function () {
  const scenes = useDesignEditorPages()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const editor = useEditor()
  const [css] = useStyletron()
  const [currentPreview, setCurrentPreview] = React.useState("")
  const frame = useFrame()
  const [draggedScene, setDraggedScene] = React.useState<IScene | null>(null)
  const contextMenuTimelineRequest = useContextMenuTimelineRequest()
  const { skuData } = useAppContext() 

  //check if url contains any path, redirect to home if it does
  useEffect(() => {
    if (window.location.pathname !== "/" && !window.location.pathname.includes("/download-")) {
      window.location.href = "/";
    }
  }, [])


  const [cart, setCart] = useState([]);
  const [size, setSize] = useState<any>({"width": 3570,"height": 2546})
  //setCurrentDesign({"name": "22"})

    useEffect(() => {
      getSkuAndSize();

    }, []);

    async function getSkuAndSize() {
      // get sku from cookie
      const sku = Cookies.get('photobookSku');

      // must clean up the cookies before exiting
      const dimensions = formatSizes.find(x => x.sku.includes(sku))

      // if sku is null or undefined redirect to localhost:3000
      if (!sku || !dimensions) {
        //window.location.href = "https://memoryboxco.co.za";
      }

      if (dimensions)
        setSize(dimensions.pixels)

      /* const { data } = await supabase.from("cart").select();
      console.log("Cart", data)
      setCart(data); */
    }


  const sensors = [
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  ]

  React.useEffect(() => {
    if (editor && scenes && currentScene) {
      const isCurrentSceneLoaded = scenes.find((s) => s.id === currentScene?.id)
      if (!isCurrentSceneLoaded) {
        setCurrentScene(scenes[0])
      }
    }
  }, [editor, scenes, currentScene])

  React.useEffect(() => {
    let watcher = async () => {
      const updatedTemplate = editor.scene.exportToJSON()
      const updatedPreview = (await editor.renderer.render(updatedTemplate,  {
        //format: "jpeg",
        //quality: 0.01,
      })) as string
      setCurrentPreview(updatedPreview)
      //setCurrentScene(updatedTemplate)
    }
    if (editor) {
      editor.on("history:changed", watcher)
    }
    return () => {
      if (editor) {
        editor.off("history:changed", watcher)
      }
    }
  }, [editor])

  React.useEffect(() => {
    const loadTemplate = async () => {
      if (editor) {
        if (currentScene) {
          updateCurrentScene(currentScene)
        } else {
          let template
          const designTemplate = await loadSavedTemplateOrDefault()
          //exampleTemplate.frame = size;
          const scaledTemplate: any = scaleTemplate(designTemplate, size)
          template = await loadGraphicTemplate(scaledTemplate)

          setCurrentScene(template.scenes[0])
          
          //   @ts-ignore
          setScenes(template.scenes)
          //   @ts-ignore
          setCurrentDesign(template.design)
        }
      }
    }
  
    loadTemplate().catch(console.error)
  }, [editor, currentScene])

  const loadSavedTemplateOrDefault = async () => {
    let designId = Cookies.get('activeDesignId')

    if (designId || designId !== "") {
      let project = localStorage.getItem('savedProject')
      if (project) {
        const projectDesign = JSON.parse(project)
        return projectDesign;
      }

      project = localStorage.getItem('currentSessionDesign')

      if (project) {
        const projectDesign = JSON.parse(project)
        Cookies.set('activeDesignId', designId, { domain: 'localhost' })
        Cookies.set('activeDesignId', projectDesign.id, { domain: '.memoryboxco.co.za' })
        return projectDesign;
      }

      //exampleTemplate2.id = designId
      //return exampleTemplate2;
    }
    else {
      console.log("No design id found in the scenes component")
      //set active id to cookie
      designId = nanoid()
      Cookies.set('activeDesignId', designId, { domain: '.memoryboxco.co.za' })
      Cookies.set('activeDesignId', designId, { domain: 'localhost' })
      exampleTemplate.id = designId

      //skuData.standard_page_size make the scenes items equal to the standard page size, take the first items
      exampleTemplate.scenes = exampleTemplate.scenes.slice(0, skuData.standard_page_size + 1)
      return exampleTemplate;
    }
  }

  

  const loadGraphicTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn.id,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)
      await loadTemplateFonts(loadedScene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      scenes.push({ ...loadedScene, preview })
    }

    return { scenes, design }
  }

  const updateCurrentScene = React.useCallback(
    async (design: IScene) => {
      await editor.scene.importFromJSON(design)
      const updatedPreview = (await editor.renderer.render(design)) as string
      setCurrentPreview(updatedPreview)
      
    },
    [editor, currentScene]
  )

  const addScene = React.useCallback(async () => {
    setCurrentPreview("")
    const updatedTemplate = editor.scene.exportToJSON()
    const updatedPreview = await editor.renderer.render(updatedTemplate)

    const updatedPages = scenes.map((p) => {
      if (p.id === updatedTemplate.id) {
        return { ...updatedTemplate, preview: updatedPreview }
      }
      return p
    })

    const defaultTemplate = getDefaultTemplate(currentDesign.frame)
    const newPreview = await editor.renderer.render(defaultTemplate)
    const newPage = { ...defaultTemplate, id: nanoid(), preview: newPreview } as any
    const items = Array.from({ length: skuData?.extra_pages_multiple }, () => ({
      ...defaultTemplate,
      id: nanoid(),
      preview: newPreview,
    } as any));
    const newPages = [...updatedPages, ...items] as any[]
    setScenes(newPages)
    setCurrentScene(items[0] as any)

  }, [scenes, currentDesign])

  const changePage = React.useCallback(
    async (page: any) => {
      setCurrentPreview("")
      if (editor) {
        const updatedTemplate = editor.scene.exportToJSON()
        const updatedPreview = await editor.renderer.render(updatedTemplate)

        const updatedPages = scenes.map((p) => {
          if (p.id === updatedTemplate.id) {
            return { ...updatedTemplate, preview: updatedPreview }
          }
          return p
        }) as any[]

        setScenes(updatedPages)
        setCurrentScene(page)
      }
    },
    [editor, scenes, currentScene]
  )

  function handleDragStart(event: any) {
    const draggedScene = scenes.find((s) => s.id === event.active.id)
    if (draggedScene) {
      setDraggedScene(draggedScene)
    }
  }

  function handleDragEnd(event: any) {
    const { active, over } = event

    if (active.id !== over.id) {
      setScenes((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
    setDraggedScene(null)
  }

  function canAddMoreScenes() {
    return scenes.length < skuData.total_spreads_per_book + 1
  }

  return (
    
    /* <DndContext
      modifiers={[restrictToFirstScrollableAncestor, restrictToHorizontalAxis]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      autoScroll={true}
    >
      
    </DndContext> */
    <Block
        id="TimelineItemsContainer"
        $style={{ padding: "0.25rem 0.75rem", background: "#ffffff", position: "relative", overflowX: "auto", width: "100%" }}
      >
        <div className={css({ display: "flex", alignItems: "center" })} id="AddScene">
          {contextMenuTimelineRequest.visible && <SceneContextMenu />}

          {scenes.map((page, index) => (
              <SceneItem
                key={index}
                isCurrentScene={page.id === currentScene?.id}
                scene={page}
                index={index}
                changePage={changePage}
                preview={
                  currentPreview && page.id === currentScene?.id ? currentPreview : page.preview ? page.preview : ""
                }
              />
            ))}
            {
              canAddMoreScenes() && 
              <div
                style={{
                  background: "#ffffff",
                  padding: "1rem 1rem 1rem 0.5rem",
                }}
              >
                <div
                  onClick={addScene}
                  className={css({
                    width: "100px",
                    height: "56px",
                    background: "rgb(243,244,246)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  })}
                >
                  <Add size={20} />
                </div>
              </div>
            }

          {/* <SortableContext items={scenes} strategy={horizontalListSortingStrategy}>
            
            
          </SortableContext> */}
          {/* <DragOverlay>
            {draggedScene ? (
              <Block
                $style={{
                  backgroundImage: `url(${draggedScene.preview})`,
                  backgroundSize: `${frame ? (frame.width * 70) / frame.height : 70}px 70px`,
                  height: "80px",
                  opacity: 0.75,
                }}
              ></Block>
            ) : null}
          </DragOverlay> */}
        </div>
      </Block>
  )
}

