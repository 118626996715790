import React, { useEffect, useRef, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"
import useAppContext from "~/hooks/useAppContext"
import Loading from "./components/Loading"
import { editorFonts } from "./constants/fonts"
import { getFonts } from "./store/slices/fonts/actions"
import { getPixabayResources } from "./store/slices/resources/actions"
import { useAppDispatch } from "./store/store"
import { getProjectForDownload, getSavedProject, getSkuData, listImages } from "./services/supabase"
import Cookies from 'js-cookie';
import { nanoid } from "@reduxjs/toolkit"

function Container({ children }: { children: React.ReactNode }) {
  const containerRef = useRef<HTMLDivElement>(null)
  const { setIsMobile, setIsTablet,setUploads, setSkuData } = useAppContext()
  const [loaded, setLoaded] = useState(false)
  const dispatch = useAppDispatch()
  const updateMediaQuery = (value: number) => {
    if (value >= 800) {
      setIsMobile(false)
      setIsTablet(false)
    } else if (value >= 600 && value < 800) {
      setIsMobile(false)
      setIsTablet(true)
    } else {
      setIsMobile(true)
      setIsTablet(false)
    }
  }
  useEffect(() => {
    const containerElement = containerRef.current!
    const containerWidth = containerElement.clientWidth
    updateMediaQuery(containerWidth)
    const resizeObserver = new ResizeObserver((entries) => {
      const { width = containerWidth } = (entries[0] && entries[0].contentRect) || {}
      updateMediaQuery(width)
    })
    resizeObserver.observe(containerElement)
    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const initialize = async () => {
      try {
        dispatch(getFonts())
        dispatch(getPixabayResources())
        loadFonts(),
        await Promise.all([
          loadUploads(),
          //loadSkuData(),
          loadOrSetActiveDesignId(),
        ])
        setLoaded(true)
      } catch (error) {
        console.error("Error during initialization:", error)
      }
    }
  
    initialize()
  }, [])
  //TODO: Load uploads from supabase and other init data
  const loadUploads = async () => {
    const uploads = await listImages("projectId")
    setUploads(uploads)
  }

  const loadSkuData = async () => {
    const photobookSku = Cookies.get('photobookSku')

    if (Cookies.get('customerId') != null) {
      localStorage.setItem('customerId', Cookies.get('customerId'))
    }
    else {
      Cookies.remove('customerId')
      localStorage.removeItem('customerId')
    }
    //console.log("photobookSku", photobookSku)
    const skuData = await getSkuData(photobookSku)
    //console.log("skuData", skuData)
    const isProduction = true

    if (!skuData && isProduction) {
      window.location.href = isProduction ? "https://memoryboxco.co.za" : "http://localhost:3000";
    }
    setSkuData(skuData)
  }

  const loadOrSetActiveDesignId = async () => {
    //get path from url
    const path = window.location.pathname
    let designId = path.split("/").pop()
    let isDownload = false;

    if (!designId) {
      Cookies.set('isDownload', isDownload);
      await loadSkuData();
      return
    }

    if (designId.includes("download-")) {
      designId = designId.replace("download-", "")
      isDownload = true;
    }
    else {
      await loadSkuData()
    }

    Cookies.set('isDownload', isDownload);

    const project = isDownload ? await getProjectForDownload(designId) : await getSavedProject(designId, Cookies.get('customerId'))

     if (project && designId) {
       //save project to local storage
        localStorage.setItem('savedProject', JSON.stringify(project.design))

        //remove currentSessionDesign
        localStorage.removeItem('currentSessionDesign')

        //set active id to cookie
        Cookies.set('activeDesignId', project.design_id, { domain: '.memoryboxco.co.za' })
        Cookies.set('activeDesignId', project.design_id, { domain: 'localhost' })
     }
  }

  const getPixabayResources2 = () => {
    dispatch(getPixabayResources())
  }

  const loadFonts = () => {
    const promisesList = editorFonts.map((font) => {
      // @ts-ignore
      return new FontFace(font.name, `url(${font.url})`, font.options).load().catch((err) => err)
    })
    Promise.all(promisesList)
      .then((res) => {
        res.forEach((uniqueFont) => {
          if (uniqueFont && uniqueFont.family) {
            document.fonts.add(uniqueFont)
          }
        })
      })
      .catch((err) => console.log({ err }))
  }

  return (
    <div
      ref={containerRef}
      style={{
        flex: 1,
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      {loaded ? <>{children} </> : <Loading />}
    </div>
  )
}

export default Container
