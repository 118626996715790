import React, { useEffect, useState } from "react"
import { Modal, ModalBody, SIZE, ROLE, ModalFooter, ModalHeader, ModalButton } from "baseui/modal"
import { Block } from "baseui/block"
import { KIND as ButtonKind } from "baseui/button";
import { set } from "lodash"

interface ComponentProps {
  isOpen: boolean
  setIsOpen: (v: boolean) => void,
  multiplier: number,
}
export default function ({ isOpen, setIsOpen, multiplier }: ComponentProps) {

  const [productMultiplier, setProductMultiplier] = React.useState(multiplier);
  
  useEffect(() => {
    setProductMultiplier(multiplier);
  }, [multiplier]);


  function close() {
    setIsOpen(false);
  }

  return (
    <Modal
        onClose={() => setIsOpen(false)}
        closeable={true}
        isOpen={isOpen}
        animate
        autoFocus
        size={"auto"}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderTopRightRadius: "8px",
              borderEndStartRadius: "8px",
              borderEndEndRadius: "8px",
              borderStartEndRadius: "8px",
              borderStartStartRadius: "8px",
            },
          },
        }}
      >
        <ModalHeader>Incorrect Number of Pages</ModalHeader>
          <Block $style={{
            //width: "60vw", 
            //height: "60vh", 
            //maxWidth: "60vh",
            //maxHeight: "60vh",
            overflow: "hidden",
            position: "relative"
        }}>
          <div style={{margin: "30px"}}>
            <p>
            The number of pages in your design does not match the selected product. Please adjust the page count to be a multiple of {productMultiplier}.
            </p>
            
          </div>
          
          </Block>
          

        <ModalFooter>
        <Block>
        <ModalButton onClick={close}>Okay</ModalButton>
        </Block>
        </ModalFooter>
      </Modal>
  )
}
